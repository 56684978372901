import CommunityDiscord from "./CommunityDiscord";
import classes from './CommunityContent.module.css';

const CommunityContent = () => {
    return (
        <>
            <div className={classes.community}>
                <CommunityDiscord></CommunityDiscord>
            </div>
        </>
    );
};

export default CommunityContent;