import DashboardDefault from '../pages/DashboardDefault';
import MainLayout from '../layouts/MainLayout';
import CoursesDefault from '../pages/CoursesDefault';
import CommunityDefault from '../pages/CommunityDefault';

const MainRoutes = {
    path: '/',
    element: <MainLayout />,
    children: [
        {
            path: '/',
            element: <DashboardDefault />
        },
        {
            path: '/tes-formations-excellence-developpement',
            element: <CoursesDefault />
        },
        {
            path: '/ta-communaute',
            element: <CommunityDefault />
        }
    ]
};

export default MainRoutes;