import GoToAction from "../../../../shared/components/ui/GoToAction";

const CommunityDiscord = () => {
    return (
        <>
            <section>
                <h1>Rejoindre le discord de la communauté <br></br>Curious academy !</h1>
                <p>
                    C'est gratuit, et c'est pour toi ! 
                </p>
                <p>
                    Tu es bloqué-e dans une étape ? Tu ne comprends pas un point dans une des formations ?<br></br>
                    La communauté est là <strong>pour t'aider, échanger avec toi</strong> !
                </p>
            </section>
            <GoToAction style={{backgroundColor: 'white', color: 'black'}} text="Rejoins-nous :)" url="https://discord.gg/Mk2Nhq3ruV"></GoToAction>
        </>
    );
};

export default CommunityDiscord;