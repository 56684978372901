import { createStore, applyMiddleware  } from "redux";
import thunk from 'redux-thunk'
import reducer from "./reducer";

const store = createStore(reducer, applyMiddleware(thunk));

// Au lieu de : 
// export interface RootState {
//     customization: CustomizationState,
//     testimonials: TestimonialsState
// }
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

const persister = 'Free';

export { store, persister };