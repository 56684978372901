import { useEffect } from "react";
import CommunityContent from "../features/community/CommunityContent";

const CommunityDefault = () => {
    useEffect(() => {
        document.title = "Ta communauté Discord - Curious academy";
     }, []);

    return (
        <>
            <CommunityContent></CommunityContent>
        </>
    );
};

export default CommunityDefault;