import React from "react";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline, StyledEngineProvider } from "@mui/material";
import "./App.css";
import { useSelector } from "react-redux";
import theme from "../../config/themes";
import NavigationScroll from "../../layouts/NavigationScroll";
import Routes from '../../routes/index';
import { RootState } from "../../store";

function App() {
  const customization = useSelector((state: RootState) => state.customization);

  return (
    <>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme(customization)}>
          <CssBaseline></CssBaseline>
          <NavigationScroll>
            <Routes />
          </NavigationScroll>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
}

export default App;
