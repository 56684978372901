import { combineReducers } from 'redux';
import testimonialsReducer, { TestimonialsState } from '../features/testimonials/store/testimonialReducer';
import customizationReducer, { CustomizationState } from './customizationReducer';
import courseReducer from '../features/courses/store/courseReducer';



const reducer = combineReducers({
    customization: customizationReducer,
    testimonials: testimonialsReducer,
    courses: courseReducer
});

export default reducer;