import Grid from '@mui/material/Grid';
import { useEffect } from 'react';
import CoursesContent from '../features/courses/CoursesContent';

const CoursesDefault = () => {
    useEffect(() => {
        document.title = "Tes formations d'excellence - Curious academy";
     }, []);

    return (
        <>
            <Grid paddingTop={'10px'}>
                <CoursesContent></CoursesContent>
            </Grid>
        </>
    );
};

export default CoursesDefault;
