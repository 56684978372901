import config from '../config/index';
import { AnyAction } from 'redux';
import * as actionTypes from './actions';

export interface CustomizationState {
    isOpen: any[]; // for active default menu
    fontFamily: string,
    borderRadius: number,
    opened: boolean
}


export const initialState: CustomizationState = {
    isOpen: [], // for active default menu
    fontFamily: config.fontFamily,
    borderRadius: config.borderRadius,
    opened: true
};

const customizationReducer = (state: CustomizationState = initialState, action: AnyAction): CustomizationState => {
    let id;

    switch (action.type) {
        case actionTypes.MENU_OPEN:
            id = action.id;
            return {
                ...state,
                isOpen: [id]
            };
        case actionTypes.SET_MENU:
            return {
                ...state,
                opened: action.opened
            };
        case actionTypes.SET_FONT_FAMILY:
            return {
                ...state,
                fontFamily: action.fontFamily
            };
        case actionTypes.SET_BORDER_RADIUS:
            return {
                ...state,
                borderRadius: action.borderRadius
            };
        default:
            return state;
    }
};

export default customizationReducer;